<template>
    <section id="clients">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="section-heading text-uppercase">{{ $t('body_checks_page') }}</h2>
                </div>
            </div>

            <div class="btn-group-toggle" data-toggle="buttons" v-for="obj in body_checks" :key="obj">
                <label class="btn btn-secondary btn-light" v-bind:for="obj.id" @click="toggle(obj.id)">
                    <input type="checkbox" v-bind:id="obj.id" v-bind:value="obj.id" v-model="checked_ids"> {{ $t(obj.content) }}
                </label>
            </div>

            <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit" v-on:click="goToEvaluation()">{{ $t('check_body_type') }}</button>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'EvaluationTest',
        data() {
            return {
                body_checks: [],
                checked_ids: new Set(),
            }
        },
        methods: {
            toggle(id) {
                if(this.checked_ids.has(id)) {
                    this.checked_ids.delete(id);
                } else {
                    this.checked_ids.add(id);
                }
            },

            async getBodyChecks() {
                try {
                    let response = await this.$http.get(process.env.VUE_APP_AI_TEA_TECH_BACKEND_HOST + "body_checks");
                    this.body_checks = response.data;
                } catch (error) {
                    console.log(error);
                }
            },

            async goToEvaluation() {
                const values = Array.from(this.checked_ids)
                this.$router.push({
                    name: "evaluation_result",
                    params: { 'checked_ids': values }
                });
            }
        },
        async created(){
            await this.getBodyChecks()
        },
    };
</script>
